export const environment = {
  production: false,
  apiUrlBackEnd: 'https://calapp-backend-prod-ffhea8beb0b0dffr.francecentral-01.azurewebsites.net/services/v1.0/',
  msalConfig: {
    auth: {
      clientId: '5d9a0cbb-7d36-42a0-a8d9-3a68b3622d79',
      authority: 'https://login.microsoftonline.com/86e9dc46-d933-4999-856e-541cadfea2ca/oauth2/v2.0/authorize',
    },
  },
  apiConfig: {
    scopes: ['api://83c9c2ff-8935-4fd6-8c1e-e17bb6684f64/Partes.All'],
    uri: 'https://calapp-backend-prod-ffhea8beb0b0dffr.francecentral-01.azurewebsites.net/services/v1.0/Usuaris/permisos/',
  },
};



