import { Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AdminGuard } from './core/guards/admin.guard';
import { AuthService } from './core/services/auth/auth.service';


export const routes: Routes = [
  {
    path: '',
    loadComponent: () => import('./pages/admin/globalLayout/globalLayout.component'),
    resolve: { permisos: AuthService },
    canActivate: [MsalGuard],
    children: [
      {
        path: 'dashboard',
        loadComponent: () => import('./pages/admin/dashboardLayout/dashboardLayout.component'),
        resolve: { permisos: AuthService }
      },
      {
        path: 'partes',
        loadComponent: () => import('./pages/partes/header/header.component'),
        resolve: { permisos: AuthService },
        canActivate: [AdminGuard]
      },
      {
        path: 'detailHeader/:id',
        loadComponent: () => import('./pages/partes/detailHeader/detailHeader.component').then(m => m.DetailHeaderComponent),
        resolve: { permisos: AuthService },
        canActivate: [AdminGuard]
      },
      {
        path: 'detailHeader',
        loadComponent: () => import('./pages/partes/detailHeader/detailHeader.component').then(m => m.DetailHeaderComponent),
        resolve: { permisos: AuthService },
        canActivate: [AdminGuard]
      },
      {
        path: 'linia/:id',
        loadComponent: () => import('./pages/partes/linia/linia.component').then(m => m.LiniaComponent),
        resolve: { permisos: AuthService },
        canActivate: [AdminGuard]
      },
      {
        path: 'linia',
        loadComponent: () => import('./pages/partes/linia/linia.component').then(m => m.LiniaComponent),
        resolve: { permisos: AuthService },
        canActivate: [AdminGuard]
      },
      {
        path: 'validador',
        loadComponent: () => import('./pages/partes/validador/validador.component'),
        resolve: { permisos: AuthService },
        canActivate: [AdminGuard]
      },
      {
        path: 'liniaValidador/:id',
        loadComponent: () => import('./pages/partes/liniaValidador/liniaValidador.component').then(m => m.LiniaValidadorComponent),
        resolve: { permisos: AuthService },
        canActivate: [AdminGuard]
      },
      {
        path: 'llistatResum',
        loadComponent: () => import('./pages/partes/llistatResum/llistatResum.component').then(m => m.LlistatResumComponent),
        resolve: { permisos: AuthService },
        canActivate: [AdminGuard]
      },
      {
        path: 'partesExcel',
        loadComponent: () => import('./pages/partes/partesExcel/partesExcel.component').then(m => m.PartesExcelComponent),
        resolve: { permisos: AuthService },
        canActivate: [AdminGuard]
      },
      {
        path: '',
        redirectTo: 'dashboard',
        resolve: { permisos: AuthService },
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'dashboard',
    resolve: { permisos: AuthService }
  }
];
