import { Inject, Injectable, OnDestroy, OnInit } from '@angular/core';
import {
  MsalService,
  MsalBroadcastService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  RedirectRequest,
  PopupRequest,
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Subject, Observable, firstValueFrom } from 'rxjs';
import { catchError, filter, takeUntil, tap } from 'rxjs/operators';
import { UsuariRols } from '../../models/usuaris/usuariRols.interface';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy, OnInit, Resolve<any> {
  private readonly _destroying$ = new Subject<void>();
  private accessToken: string | null = null; // Variable para almacenar el accessToken
  private rolsUrl = environment.apiUrlBackEnd + 'usuaris/rols';
  loginDisplay = false;
  public usuariRols!: UsuariRols;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.authService.handleRedirectObservable().subscribe();
    this.setLoginDisplay();

    this.authService.instance.enableAccountStorageEvents();

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACCOUNT_ADDED ||
            msg.eventType === EventType.ACCOUNT_REMOVED
        )
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = '/';
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  logout() {
    this.authService.logoutRedirect();
  }

  login() {
    this.authService.loginRedirect();
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.getPermisos();
  }

  getUserName(): string {
    const activeAccount = this.authService.instance.getActiveAccount();
    if (activeAccount) {
      console.log('activeAccount:', activeAccount.name);
      return activeAccount.name || '';
    } else {
      return '';
    }
  }

  async getPermisos(): Promise<UsuariRols> {
    return await firstValueFrom(this.http.get<UsuariRols>(this.rolsUrl));
  }

  getPermisosObservable(): Observable<UsuariRols> {
    return this.http.get<UsuariRols>(this.rolsUrl);
  }

  getToken() {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (activeAccount) {
      const request = {
        scopes: ["api://83c9c2ff-8935-4fd6-8c1e-e17bb6684f64/Partes.All"], // Afegeix els scopes que necessitis
        account: activeAccount
      };

      this.authService.acquireTokenSilent(request).subscribe({
        next: (response: AuthenticationResult) => {
          // console.log("Token d'autorització:", response.accessToken);
          localStorage.setItem('accessToken', response.accessToken);
          // Pots guardar el token o utilitzar-lo segons les teves necessitats
        },
        error: (error) => {
          console.error("Error obtenint el token:", error);
          // Pots gestionar l'error, per exemple, fent un acquireTokenPopup
          this.authService.acquireTokenPopup(request).subscribe({
            next: (response: AuthenticationResult) => {
              // console.log("Token d'autorització:", response.accessToken);
              // Pots guardar el token o utilitzar-lo segons les teves necessitats
            },
            error: (popupError) => {
              console.error("Error obtenint el token amb popup:", popupError);
            }
          });
        }
      });
    } else {
      console.error("No hi ha cap compte actiu.");
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
