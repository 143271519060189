import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AdminGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    const permisos = await this.authService.getPermisos();

    if ((state.url.startsWith('/partes') || state.url.startsWith('/detailHeader') || state.url.startsWith('/linia') ||
        state.url.startsWith('/llistatResum') || state.url.startsWith('/partesExcel'))
        && permisos.esAdminPartes) {
      return true;
    } else if ((state.url.startsWith('/validador') || state.url.startsWith('/liniaValidador')) && permisos.esValidadorPartes) {
      return true;
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}
